import { styled } from "styled-components";

import {
  AvatarButton,
  Icon,
  Menu as MenuBase,
  Stack,
} from "@fidelix/fx-miranda";

import { useCurrentUserQuery } from "~user/user.queries";
import { Trans } from "@lingui/macro";
import { config } from "../config";

export function NavMenu() {
  const { data: user } = useCurrentUserQuery();

  return (
    <Menu
      trigger={
        <AvatarButton
          name={user?.fullName || ""}
          data-test-id="nav-menu-button"
        />
      }
    >
      <Menu.Item
        href={`${config.ADMIN_URL}/me`}
        target="_blank"
        data-test-id="nav-menu-item-logout"
      >
        <Stack axis="x" spacing="xsmall" align="center">
          <Icon icon="account" size={18} />
          <Trans>My account</Trans>
        </Stack>
      </Menu.Item>
      <Menu.Item href="/logout" data-test-id="nav-menu-item-logout">
        <Stack axis="x" spacing="xsmall" align="center">
          <Icon icon="logOut" size={18} />
          <Trans>Logout</Trans>
        </Stack>
      </Menu.Item>
    </Menu>
  );
}

const Menu = styled(MenuBase)`
  min-width: 250px;
`;
