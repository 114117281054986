import { Device } from "../device/device.model";
import { PointType, PointValue } from "../point/point.model";

export class Alarm {
  id: number;
  name: string;
  device: Device;
  buildingName: string | null;
  description: string;
  type: PointType;
  unit: string | null;
  value?: PointValue;
  stateTexts?: { label: string; value: number }[];
  alarmGroup: string | null;
  isActive?: boolean;

  constructor(alarm: Alarm) {
    this.id = alarm.id;
    this.name = alarm.name;
    this.device = alarm.device;
    this.buildingName = alarm.buildingName || null;
    this.description = alarm.description;
    this.type = PointType.Alarm;
    this.unit = alarm.unit;
    this.value = alarm.value;
    this.stateTexts = alarm.stateTexts;
    this.alarmGroup = alarm.alarmGroup;
    this.isActive = alarm.isActive;
  }
}
