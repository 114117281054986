import { matchPath } from "react-router-dom";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import {
  Token,
  getAccessToken,
  getRefreshToken,
  saveToken,
} from "~utils/session";
import { config } from "../config";

export interface CustomConfig extends AxiosRequestConfig {
  headers: Record<string, string>;
}

export class Http {
  protected _axios: AxiosInstance;
  private _subscribers: ((token: string | undefined) => void)[] = [];
  private _refreshing = false;

  constructor(baseUrl: string) {
    this._axios = this.createClient(baseUrl);
    this.addInterceptors();
  }

  createClient(url: string | undefined = undefined) {
    return axios.create({
      baseURL: url,
    });
  }

  addInterceptors() {
    this.addBearerTokenInterceptor();
    this.addUnauthorisedInterceptor();
  }

  addBearerTokenInterceptor() {
    this._axios.interceptors.request.use((config) => {
      const token = getAccessToken();

      if (token) {
        config.headers.set("Authorization", `Bearer ${token}`);
      }

      return config;
    });
  }

  addUnauthorisedInterceptor() {
    this._axios.interceptors.response.use(
      async (response: AxiosResponse) => response,
      async (error: AxiosError) => this.handleAxiosError(error),
    );
  }

  handleAxiosError(error: AxiosError): Promise<AxiosResponse> {
    const config = error?.config as CustomConfig;

    if (error?.response?.status === 403 || error?.response?.status === 401) {
      if (!this._refreshing) {
        this._refreshing = true;
        this.refreshAccessToken().then(
          (accessToken: string | undefined) => {
            this._refreshing = false;
            this.onRefreshed(accessToken);
          },
          () => {
            // refresh token failed, last option is to redirect user to login again
            this.redirectToLogin();
          },
        );
      }
      return this.retryRequest(config);
    } else {
      return Promise.reject(error);
    }
  }

  public onRefreshed(token: string | undefined) {
    this._subscribers.forEach((cb: (token: string | undefined) => void) => {
      return cb(token);
    });
    this._subscribers = [];
  }

  refreshAccessToken() {
    // Get current company id from url params
    const match = matchPath("/company/:companyId/*", window.location.pathname);
    const companyId = Number(match?.params.companyId);

    return this.refreshToken(companyId); // Perform the token refresh
  }

  refreshTokenRequest(refreshToken: string, companyId: number) {
    const http = this.createClient();
    return http.get<Token>(`${config.ADMIN_API_URL}/authenticate`, {
      params: { refreshToken, companyId },
    });
  }

  refreshToken(companyId: number): Promise<string> {
    const refToken = getRefreshToken();

    if (!refToken) {
      return Promise.reject();
    }

    const request = this.refreshTokenRequest(refToken, companyId);

    return request.then(({ data }) => {
      saveToken({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });

      return data.access_token;
    });
  }

  retryRequest(config: CustomConfig): Promise<AxiosResponse> {
    return new Promise((resolve, reject) => {
      this.subscribeTokenRefresh((token) => {
        if (!token) {
          reject();
          return;
        }

        config.headers.authorization = "Bearer " + token;

        this.retry(config)
          .then((o: AxiosResponse) => resolve(o))
          .catch((err) => {
            const path = this.handleRedirectPathsOnError(err.response.status);
            if (path) reject(window.location.replace(path));
            reject(err);
          });
      });
    });
  }

  subscribeTokenRefresh(cb: (token: string | undefined) => void) {
    this._subscribers.push(cb);
  }

  handleRedirectPathsOnError(errorCode: number) {
    // If you get 403, it means the token is valid but you have no access, thus we can't redirect you to a view that gives you that information.
    if (errorCode === 403) {
      return "/auth/unauthorized";
    }

    // If you get 401, it means the access token is somehow not valid even after refresh. I assume the most common reason is that your refresh token is also expired, so then we redirect user to login
    if (errorCode === 401) {
      // Save the current path so we can redirect back to it after login
      const from = window.location.pathname + window.location.search;
      const baseUrl = new URL(`${config.BASE_URL}/auth/callback`);
      baseUrl.searchParams.append("from", from);
      const redirectUrl = new URL(`${config.ADMIN_URL}/login`);
      redirectUrl.searchParams.append("redirectUrl", baseUrl.toString());
      return redirectUrl.toString();
    }

    return "";
  }

  redirectToLogin() {
    const path = this.handleRedirectPathsOnError(401);
    window.location.replace(path);
  }

  retry(config: CustomConfig) {
    return axios(config);
  }

  public delete(resource: string) {
    return this._axios.delete(resource);
  }

  public put<T>(resource: string, data: unknown) {
    return this._axios.put<T>(resource, data);
  }

  public post<T>(resource: string, data: unknown, config?: AxiosRequestConfig) {
    return this._axios.post<T>(resource, data, config);
  }

  public get<T>(
    resource: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> {
    return this._axios.get<T>(resource, config);
  }
}
