import React from "react";
import { styled } from "styled-components";
import { Outlet } from "react-router-dom";
import { DeviceTree } from "./device-tree";

export function GraphicsRoute() {
  return (
    <Container>
      <Sidebar>
        <DeviceTree />
      </Sidebar>
      <Outlet />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
`;

const Sidebar = styled.aside`
  position: relative;
  width: 300px;
  border-right: 1px solid ${(p) => p.theme.colors.lightNeutral};
`;
