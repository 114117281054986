export class Level {
  id: number;
  name: string;
  parentId: number | null;
  isBuilding: boolean;
  hasChildren: boolean;
  parent?: Level | null;

  constructor(level: Level) {
    this.id = level.id;
    this.name = level.name;
    this.parentId = level.parentId;
    this.isBuilding = level.isBuilding;
    this.hasChildren = level.hasChildren;
    this.parent = level?.parent;
  }
}
