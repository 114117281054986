import "@fidelix/fx-miranda/base.css";
import "./index.css";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { I18nProvider as AriaI18nProvider } from "react-aria-components";
import { UikitProvider } from "@fidelix/fx-miranda";
import { RouterProvider } from "react-router-dom";
import { Settings } from "luxon";
import { defaultLocale, loadMessages } from "./utils/i18n";
import { queryClient } from "./utils/query-client";
import { router } from "./router";

async function init() {
  await loadMessages(defaultLocale);
  /**
   * Throw an error when parsing invalid date strings.
   * This makes using parsed dates much easier with TS as you don't have to
   * check if the date is valid before using it.
   */
  Settings.throwOnInvalid = true;

  const root = createRoot(document.getElementById("root") as Element);

  root.render(
    <QueryClientProvider client={queryClient}>
      <I18nProvider i18n={i18n}>
        <AriaI18nProvider>
          <UikitProvider>
            <RouterProvider router={router} />
          </UikitProvider>
        </AriaI18nProvider>
      </I18nProvider>
    </QueryClientProvider>,
  );
}

init();

/**
 * Clear any service workers that were registered by Create React App.
 *
 * TODO: delete this block of code once this change has been deployed
 * and all old service workers have been unregistered. We might want to add
 * a service worker for this app later, but for now, it's not needed.
 */
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();

    if (caches) {
      caches.keys().then(async (names) => {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
    }
  });
}
