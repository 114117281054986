import { queryOptions, useQuery } from "@tanstack/react-query";

import { assetsApiRequest } from "~api/api-request";
import { useRequiredParams } from "~utils/url";
import { ApiResponse, ApiResponsePaginated } from "~api/api-response.model";
import { Device } from "./device.model";

export const deviceQueries = {
  all: (params: DeviceParams) =>
    queryOptions({
      queryKey: ["devices", params],
      queryFn: () => fetchDevices(params),
    }),
  detail: (params: { companyId: number; id?: number }) =>
    queryOptions({
      queryKey: ["device-detail", params],
      queryFn: () => fetchDevice(params),
    }),
};

// Queries

export function useDevicesQuery(params) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(deviceQueries.all({ companyId: companyId, ...params }));
}

export function useDeviceQuery(
  id?: number,
  queryOption?: { enabled: boolean },
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    ...queryOption,
    ...deviceQueries.detail({ companyId, id }),
  });
}

// Fetchers

type DeviceParams = {
  companyId: number;
  page: number;
  pageSize: number;
  levelId?: number;
};

async function fetchDevices(params: DeviceParams) {
  return assetsApiRequest
    .get<
      ApiResponsePaginated<Device[]>
    >(`analytics/companies/${params.companyId}/devices`, { params })
    .then((resp) => resp.data.data.map((d) => new Device(d)));
}

async function fetchDevice(params: { companyId: number; id?: number }) {
  if (!params.id) {
    throw new Error("deviceId not defined");
  }
  return assetsApiRequest
    .get<
      ApiResponse<Device>
    >(`analytics/companies/${params.companyId}/devices/${params.id}`)
    .then((resp) => new Device(resp.data.data));
}
