import { Outlet } from "react-router-dom";
import { useCurrentUserQuery } from "~user/user.queries";
import { useLingui } from "@lingui/react";
import { Settings } from "luxon";
import { defaultLocale, loadMessages } from "../utils/i18n";

/**
 * The concept of a protected route is to prevent unauthorized users from
 * accessing the route. This is done by simply trying to fetch the current user
 * and if the user is NOT logged in, the user will be automatically redirected
 * to the login page by the http client.
 *
 * See `redirectToLogin` method of `Http` class in `http.ts` for more details.
 */
export function ProtectedRoute() {
  const { isFetched, data: user } = useCurrentUserQuery();
  const { i18n } = useLingui();

  if (!isFetched) return null; // show nothing while we are fetching

  const browserLanguage = Intl.DateTimeFormat().resolvedOptions().locale;

  // Dates are formatted by the language used in the browser, not the one requested from Admin
  if (user?.language && user.language !== i18n.locale) {
    loadMessages(user.language);
  }

  Settings.defaultLocale = browserLanguage ?? defaultLocale;

  return <Outlet />;
}
