import { queryOptions, useQuery } from "@tanstack/react-query";

import { adminApiRequest } from "~api/api-request";
import { useRequiredParams } from "~utils/url";
import { ApiResponse } from "~api/api-response.model";
import { useSuspenseQueryDeferred } from "~utils/query-hooks";
import { Company } from "./company.model";

export const companyQueries = {
  all: queryOptions({
    queryKey: ["companies"],
    queryFn: fetchCompanies,
  }),
  detail: (id: number) =>
    queryOptions({
      queryKey: ["companies", id],
      queryFn: () => fetchCompany(id),
    }),
};

// Queries

export function useCompaniesQuery() {
  return useQuery(companyQueries.all);
}

export function useCurrentCompanyQuery() {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(companyQueries.detail(companyId));
}

export function useCurrentCompanySuspenseQuery() {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(companyQueries.detail(companyId));
}

// Fetchers

async function fetchCompanies() {
  return adminApiRequest
    .get<ApiResponse<Company[]>>("api/internal/companies", {
      params: { permission: "analytics" },
    })
    .then((resp) => resp.data.data.map((d) => new Company(d)));
}

async function fetchCompany(id: number) {
  return adminApiRequest
    .get<ApiResponse<Company>>(`api/internal/companies/${id}`, {
      params: { permission: "analytics" },
    })
    .then((resp) => new Company(resp.data.data));
}
