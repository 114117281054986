import { config } from "../config";

const fxfiAccessToken = "fxfi-access-token";
const fxfiRefreshToken = "fxfi-refresh-token";
const fxfiRefreshUrl = "fxfi-refresh-url";

export type Token = {
  access_token: string;
  refresh_token: string;
};

export function saveToken(token: {
  accessToken: string;
  refreshToken: string;
}) {
  sessionStorage.setItem(fxfiAccessToken, token.accessToken);
  sessionStorage.setItem(fxfiRefreshToken, token.refreshToken);
}

export function getAccessToken() {
  return sessionStorage.getItem(fxfiAccessToken);
}

export function getRefreshToken() {
  return sessionStorage.getItem(fxfiRefreshToken);
}

export function isAuthenticated() {
  return Boolean(sessionStorage.getItem(fxfiAccessToken));
}

export function removeSession() {
  sessionStorage.removeItem(fxfiAccessToken);
  sessionStorage.removeItem(fxfiRefreshToken);
  sessionStorage.removeItem(fxfiRefreshUrl);
}

export function logout() {
  removeSession();
  window.location.href = `${config.ADMIN_URL}/logout?redirectUrl=${config.BASE_URL}`;
}
