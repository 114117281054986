import {
  ComponentType,
  ForwardRefExoticComponent,
  ForwardedRef,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
  Suspense,
  createElement,
  forwardRef,
} from "react";

import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "@tanstack/react-query";

import { ErrorView } from "./error-view";
import { LoadingView } from "./loading-view";

/**
 * A boundary that wraps around the entire application to catch and handle
 * loading states for queries when using suspense.
 */
export function QueryBoundary({ children }: { children: ReactNode }) {
  return (
    <Suspense fallback={<LoadingView />}>
      <QueryErrorBoundary>{children}</QueryErrorBoundary>
    </Suspense>
  );
}

/**
 * A boundary that catches and handles errors for queries when using suspense.
 * The default error view allows the user to reset the query and try again.
 */
function QueryErrorBoundary({ children }: { children: ReactNode }) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <ErrorView onReset={resetErrorBoundary} />
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

/**
 * A higher-order component that wraps a component in a QueryBoundary.
 * Wrap page level components in this to display loading indicator and
 * catch plus handle query errors.
 */
export function withQueryBoundary<Props extends object>(
  component: ComponentType<Props>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ForwardRefExoticComponent<PropsWithoutRef<Props> & RefAttributes<any>> {
  const Wrapped = forwardRef<ComponentType<Props>, Props>(
    (props: Props, ref: ForwardedRef<ComponentType<Props>>) => {
      return (
        <QueryBoundary>
          {createElement(component, { ...props, ref })}
        </QueryBoundary>
      );
    },
  );

  // Format for display in DevTools
  const name = component.displayName || component.name || "Unknown";

  Wrapped.displayName = `withQueryBoundary(${name})`;

  return Wrapped;
}
