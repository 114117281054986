import { IconButton, Menu, TreeView } from "@fidelix/fx-miranda";
import { useSpinDelay } from "spin-delay";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import { Trans } from "@lingui/macro";
import {
  useBuildingsSuspenseQuery,
  useLevelGraphicsQuery,
  useLevelsQuery,
} from "../level/level.queries";
import { useDevicesQuery } from "../device/device.queries";
import { Level } from "../level/level.model";
import { Device } from "../device/device.model";
import { useGraphicsQuery } from "./graphics.queries";
import { MenuItems } from "./graphics.model";

export function DeviceTree() {
  const { data: levels = [] } = useBuildingsSuspenseQuery();

  return (
    <TreeView>
      {levels.map((level) => {
        return (
          <DeviceItemTree key={level.id} level={level} isBuilding isRoot />
        );
      })}
    </TreeView>
  );
}

function DeviceItemTree({
  level,
  isRoot = false,
  isBuilding = false,
  ...rest
}: {
  level: Level;
  isRoot?: boolean;
  isBuilding?: boolean;
}) {
  const { data: devices = [], isLoading: isDevicesLoading } = useDevicesQuery({
    pageSize: Number.MAX_SAFE_INTEGER,
    page: 1,
    levelId: level.id,
  });

  const { data: levelGraphics = [] } = useLevelGraphicsQuery({
    levelId: level.id,
  });

  const { data: subLevels = [], isLoading: isSubLevelsLoading } =
    useLevelsQuery({
      levelId: level.id,
    });

  const isLoading = useSpinDelay(isDevicesLoading || isSubLevelsLoading);

  if (!isRoot && isLoading) {
    return <TreeView.Loader />;
  }

  return (
    <TreeView.Item
      label={level.name}
      icon={isBuilding ? "building" : undefined}
      renderItem={(content) => (
        <HoverActions levelId={level.id}>{content}</HoverActions>
      )}
      {...rest}
    >
      {levelGraphics.length > 0 &&
        levelGraphics.map((graphic, index: number) => (
          <TreeView.Item
            label={graphic.name}
            key={`${graphic.name}-${graphic.levelId}`}
          >
            {(graphic.items || []).map((item) => (
              <MenuItem
                item={item}
                entityId={level.id}
                key={`${item.name}-${level.id}-${index}`}
                graphicsId={graphic.id}
                path={[graphic.name, item.name]}
                isLevel={true}
              />
            ))}
          </TreeView.Item>
        ))}
      {subLevels.length > 0 &&
        subLevels.map((subLevel) => (
          <DeviceItemTree
            level={subLevel}
            key={subLevel.id}
            isBuilding
            isRoot
          />
        ))}

      {devices.map((device) => (
        <DeviceTreeItem
          device={device}
          key={device.id}
          graphicsId={device.graphics.find((e) => e)?.id}
        />
      ))}
    </TreeView.Item>
  );
}

function DeviceTreeItem({
  device,
  graphicsId,
}: {
  device: Device;
  graphicsId?: number;
}) {
  const { data: graphics = [] } = useGraphicsQuery({
    graphicsId: graphicsId ? graphicsId : 0,
    enabled: !!graphicsId,
  });

  return (
    <TreeView.Item label={device.name} key={device.id}>
      {graphics.length > 0 &&
        graphics.map((graphic, index: number) => (
          <TreeView.Item
            label={graphic.name}
            key={`${graphic.name}-${graphic.deviceId}`}
          >
            {(graphic.items || []).map((item) => (
              <MenuItem
                item={item}
                graphicsId={graphic.id}
                entityId={device.id}
                isLevel={false}
                key={`${item.name}-${device.id}-${index}`}
                path={[graphic.name, item.name]}
              />
            ))}
          </TreeView.Item>
        ))}
    </TreeView.Item>
  );
}

function MenuItem({
  item,
  entityId,
  graphicsId,
  path,
  isLevel,
}: {
  graphicsId: number;
  item: MenuItems;
  entityId: number;
  path: string[];
  isLevel: boolean;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onMenuLevelSelect = (
    entityId: number,
    graphicsId: number,
    graphicPath: string,
  ) => {
    navigate(
      `${isLevel ? "levels" : "devices"}/${entityId}/menus?path=${JSON.stringify(path)}&graphic=${graphicPath}&graphicsId=${graphicsId}`,
    );
  };

  const menuPath = searchParams.get("path") || "";
  const menus = menuPath ? JSON.parse(menuPath) : [];

  return (
    <TreeView.Item
      label={item.name}
      key={`${item.name}-${entityId}`}
      isSelected={
        item.name.trim() === menus[menus.length - 1]?.trim() &&
        item.path === searchParams.get("graphic")
      }
      onSelect={() => onMenuLevelSelect(entityId, graphicsId, item.path)}
    >
      {item.items.length > 0 &&
        item.items.map((m, index: number) => (
          <MenuItem
            item={m}
            graphicsId={graphicsId}
            entityId={entityId}
            key={`${m.name}-${entityId}-${index}`}
            path={[...path, m.name]}
            isLevel={isLevel}
          />
        ))}
    </TreeView.Item>
  );
}

function HoverActions({
  children,
  levelId,
}: {
  children: ReactNode;
  levelId: number;
}) {
  const [isHovered, setHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <LevelHoverActionsWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
      {isHovered && (
        <LevelHoverActionsContent>
          <Menu
            onAction={() => navigate(`levels/${levelId}/add-graphics`)}
            trigger={<IconButton icon="plus" size="small" />}
          >
            <Menu.Item id="connect">
              <Trans>Add Graphic</Trans>
            </Menu.Item>
          </Menu>
        </LevelHoverActionsContent>
      )}
    </LevelHoverActionsWrapper>
  );
}

const LevelHoverActionsWrapper = styled.div`
  position: relative;
`;

const LevelHoverActionsContent = styled.div`
  position: absolute;
  right: ${(p) => p.theme.spacing.xxsmall}px;
  top: 50%;
  transform: translateY(-50%);
`;
