import { queryOptions, useQuery } from "@tanstack/react-query";

import { assetsApiRequest } from "~api/api-request";
import { Graphics } from "~graphics/graphics.model";
import { useRequiredParams } from "~utils/url";
import { ApiResponse } from "~api/api-response.model";
import { useSuspenseQueryDeferred } from "~utils/query-hooks";
import { Level } from "./level.model";

type LevelParams = {
  levelId: number;
  companyId: number;
};

export const levelQueries = {
  buildings: (companyId: number) =>
    queryOptions({
      queryKey: ["levels", "buildings", companyId],
      queryFn: () => fetchBuildings(companyId),
    }),
  levels: (params: LevelParams) =>
    queryOptions({
      queryKey: ["levels", "levels", params],
      queryFn: () => fetchLevels(params),
    }),
  detail: (params: LevelParams) =>
    queryOptions({
      queryKey: ["levels", "level", params],
      queryFn: () => fetchLevel(params),
    }),
  graphics: (params: LevelParams) =>
    queryOptions({
      queryKey: ["level-graphics", params],
      queryFn: () => fetchLevelGraphics(params),
    }),
};

// Queries

export function useBuildingsSuspenseQuery() {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(levelQueries.buildings(companyId));
}

export function useLevelsQuery({
  levelId,
  enabled = true,
}: {
  levelId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });

  return useQuery({
    enabled,
    ...levelQueries.levels({ companyId, levelId }),
  });
}

export function useLevelQuery(levelId: number) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(levelQueries.detail({ companyId, levelId }));
}

export function useLevelGraphicsQuery({
  levelId,
  enabled = true,
}: {
  levelId: number;
  enabled?: boolean;
}) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery({
    enabled,
    ...levelQueries.graphics({ companyId, levelId }),
  });
}

// Fetchers

async function fetchBuildings(companyId: number) {
  return assetsApiRequest
    .get<ApiResponse<Level[]>>(`analytics/companies/${companyId}/levels`, {
      params: { isBuilding: true },
    })
    .then((resp) => resp.data.data.map((l) => new Level(l)));
}

async function fetchLevels(params: LevelParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Level[]>
    >(`analytics/companies/${params.companyId}/levels/${params.levelId}/levels`)
    .then((resp) => resp.data.data.map((l) => new Level(l)));
}

async function fetchLevel(params: LevelParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Level>
    >(`analytics/companies/${params.companyId}/levels/${params.levelId}`)
    .then((resp) => new Level(resp.data.data));
}

async function fetchLevelGraphics(params: LevelParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Graphics[]>
    >(`analytics/companies/${params.companyId}/levels/${params.levelId}/graphics`)
    .then((resp) => resp.data.data.map((d) => new Graphics(d)));
}
