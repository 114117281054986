import { Graphics } from "../graphics/graphics.model";
import { Level } from "../level/level.model";

export class Device {
  id: number;
  name: string;
  ipAddress: string;
  port: string;
  authKey: string;
  levelId: number;
  externalId: string;
  level?: Level;
  graphics: Graphics[];

  constructor(device: Device) {
    this.id = device.id;
    this.name = device.name;
    this.ipAddress = device.ipAddress;
    this.port = device.port;
    this.authKey = device.authKey;
    this.levelId = device.levelId;
    this.externalId = device.externalId;
    this.level = device.level;
    this.graphics = device.graphics;
  }
}
