export type MenuItems = {
  name: string;
  path: string;
  items: MenuItems[];
};

export class Graphics {
  name: string;
  deviceId: number;
  items: MenuItems[];
  id: number;
  levelId: number;

  constructor(model: Graphics) {
    this.deviceId = model.deviceId;
    this.name = model.name;
    this.items = model.items;
    this.id = model.id;
    this.levelId = model.levelId;
  }
}

export class GraphicsAttachment {
  publicUrl: string;
  name: string;
  constructor(data: GraphicsAttachment) {
    this.publicUrl = data.publicUrl;
    this.name = data.name;
  }
}
