import { styled } from "styled-components";
import { Spinner } from "@fidelix/fx-miranda";

export function LoadingView() {
  return (
    <Container>
      <Spinner size="large" />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
