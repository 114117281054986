import { queryOptions, useQuery } from "@tanstack/react-query";

import { adminApiRequest } from "~api/api-request";
import { User } from "~user/user.model";
import { ApiResponse } from "~api/api-response.model";

export const userQueries = {
  current: queryOptions({
    queryKey: ["currentUser"],
    queryFn: fetchCurrentUser,
  }),
};

// Queries

export function useCurrentUserQuery() {
  return useQuery(userQueries.current);
}

// Fetchers

async function fetchCurrentUser() {
  return adminApiRequest
    .get<ApiResponse<User>>("me")
    .then((resp) => new User(resp.data.data));
}
