import { Stack, TopNavigation, TopNavigationItems } from "@fidelix/fx-miranda";
import { t } from "@lingui/macro";
import { useNavigatonPath } from "~utils/url";
import { CompanySelect } from "../company/company-select";
import { AlarmNotificationBell } from "../alarm/alarm-notification-bell";
import { NavMenu } from "./nav-menu";

export function Navbar() {
  const { to, isActive } = useNavigatonPath();

  const items: TopNavigationItems = [
    {
      to: to("graphics"),
      label: t`Graphics`,
      isActive: isActive("graphics"),
    },
    {
      to: to("alarms"),
      label: t`Alarms`,
      isActive: isActive("alarms"),
    },
    {
      to: to("history"),
      label: t`History`,
      isActive: isActive("history"),
    },
    {
      to: to("points"),
      label: t`Points`,
      isActive: isActive("points"),
    },
  ];

  return (
    <TopNavigation variant="primary">
      <TopNavigation.Logo to="/dashboard" />
      <TopNavigation.Items items={items} />
      <TopNavigation.Actions>
        <Stack axis="x" spacing="medium" align="center">
          <CompanySelect />
          <AlarmNotificationBell />
          <NavMenu />
        </Stack>
      </TopNavigation.Actions>
    </TopNavigation>
  );
}
