import { queryOptions, useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash-es";

import { useRequiredParams } from "~utils/url";
import { ApiResponse } from "~api/api-response.model";
import { useSuspenseQueryDeferred } from "~utils/query-hooks";
import { t } from "@lingui/macro";
import { assetsApiRequest } from "../api/api-request";
import { Alarm } from "./alarm.model";
import { AlarmTab } from "./alarm.utils";

type AlarmListParams = {
  companyId: number;
  tab?: AlarmTab;
  search?: string;
  sort?: string;
  order?: string;
  page: number;
  pageSize: number;
  groups?: string[];
  lockStates?: string[];
};

type AlarmDetailsParams = {
  companyId: number;
  deviceId: number;
  id: number;
};

type ExtraAlarmQueryOptions = Omit<typeof queryOptions, "queryKey" | "queryFn">;

export const alarmQueries = {
  list: (params: AlarmListParams, options?: ExtraAlarmQueryOptions) =>
    queryOptions({
      queryKey: ["alarms", params],
      queryFn: () => fetchAlarms(params),
      ...options,
    }),
  details: (params: AlarmDetailsParams) =>
    queryOptions({
      queryKey: ["alarm-details", params],
      queryFn: () => fetchAlarm(params),
    }),
};

// Queries

export function useAlarmsQuery(
  params: Omit<AlarmListParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useQuery(alarmQueries.list({ companyId, ...params }, options));
}

export function useAlarmsSuspenseQuery(
  params: Omit<AlarmListParams, "companyId">,
  options?: ExtraAlarmQueryOptions,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    alarmQueries.list({ companyId, ...params }, options),
  );
}

export function useAlarmSuspenseQuery(
  params: Omit<AlarmDetailsParams, "companyId">,
) {
  const { companyId } = useRequiredParams({ companyId: "number" });
  return useSuspenseQueryDeferred(
    alarmQueries.details({ companyId, ...params }),
  );
}

// Fetchers

async function fetchAlarms({
  companyId,
  tab,
  order,
  search,
  sort,
  page,
  pageSize,
  groups,
  lockStates,
}: AlarmListParams) {
  const alarms = await assetsApiRequest
    .get<
      ApiResponse<Alarm[]>
    >(`analytics/companies/${companyId}/alarms?pageSize=${Number.MAX_SAFE_INTEGER}&page=1`)
    .then((resp) => resp.data.data.map((d) => new Alarm(d)));

  // TODO: Remove pagination from frontend and use Assets API paging instead, this is doable
  // when we get an endpoint for fetching all the alarm groups in the API

  // Filter duplicate values from alarm groups, null values were causing crashes in the UI - they are now calle NO GROUP instead
  const alarmGroups = [
    ...new Set(alarms.map((a) => (a.alarmGroup ? a.alarmGroup : t`NO GROUP`))),
  ];

  const tabData =
    tab != undefined
      ? alarms.filter((alarm) => filterAlarmByActiveStatus(alarm, tab))
      : alarms;

  const groupedData =
    groups != undefined && groups.length > 0
      ? groups.flatMap((g) =>
          tabData.filter((a) =>
            g === t`NO GROUP` ? a.alarmGroup === null : a.alarmGroup === g,
          ),
        )
      : tabData;

  const lockStateData =
    lockStates != undefined && lockStates.length > 0
      ? lockStates.flatMap((g) =>
          groupedData.filter((a) => String(a.value?.lockState) === g),
        )
      : groupedData;

  const searchedData = search
    ? lockStateData.filter(
        (p) =>
          p.name.toLowerCase().includes(search.toLowerCase()) ||
          p.description.toLowerCase().includes(search.toLowerCase()),
      )
    : lockStateData;

  const sortedData = sort
    ? orderBy(searchedData, sort, order as "asc" | "desc")
    : searchedData;

  return {
    alarms: sortedData.slice((page - 1) * pageSize, page * pageSize),
    pagination: {
      total: sortedData.length,
      totalPages: Math.ceil(sortedData.length / pageSize),
      page,
      pageSize,
    },
    alarmGroups,
  };
}

async function fetchAlarm(params: AlarmDetailsParams) {
  return assetsApiRequest
    .get<
      ApiResponse<Alarm>
    >(`analytics/companies/${params.companyId}/devices/${params.deviceId}/points/${params.id}`)
    .then((resp) => new Alarm(resp.data.data));
}

// Utils

function filterAlarmByActiveStatus(alarm: Alarm, tab: AlarmTab) {
  switch (tab) {
    case "active":
      return alarm.isActive === true;
    case "unacknowledged":
      return alarm.value?.alarmState === 0;
    default:
      return true;
  }
}
